.benefits-section {
  .benefits-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;

    article {
      flex: 1;
      min-width: 300px;
      padding: 3.2rem;
      background: #fafafa;
      border-radius: 8px;

      &:first-child, &:last-child {
        min-width: 60%;
      }

      img {
        box-sizing: content-box;
        width: 24px;
        padding: 20px;
        background: #FDEB08;
        border-radius: 50%;
      }

      h3 {
        margin-top: 2.4rem;
        margin-bottom: 1.2rem;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .benefits-section {
    .benefits-grid {
      gap: 2.4rem;

      article {
        min-width: 100%;
        padding: 1.6rem;

        img {
          padding: 16px;
        }

        h3 {
          margin-top: 1.6rem;
          margin-bottom: 0.8rem;
        }
      }
    }
  }
}
