.about-section {
  background: #fafafa;

  article {
    display: flex;
    gap: 2.4rem;

    & > * {
      flex: 1;
    }
  }

  img {
    border-radius: 16px;
    height: 100%;
    object-fit: cover;
  }

  ul, li {
    list-style-type: disc;
  }

  ul {
    margin-top: 1.6rem;
    padding-left: 2rem;
    display: grid;
    gap: 0.8rem;
    font-size: 2rem;
  }
}

@media screen and (max-width: 1080px) {
  .about-section {
    article {
      flex-direction: column;
      gap: 1.6rem;
    }

    img {
      border-radius: 8px;
    }

    ul {
      font-size: 1.5rem;
    }
  }
}
