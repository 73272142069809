.services-section {
  .services-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 3.2rem;

    figure {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 300px;

      img {
        width: 100%;
        height: 250px;
        object-fit: contain;
        border-radius: 8px;
      }

      figcaption {
        width: 100%;
        display: flex;
        justify-content: space-around;
        font-size: 2.2rem;
        margin-top: 0.4rem;
        line-height: 1.35;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .services-section .services-grid figure figcaption {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 740px) {
  .services-section {
    .services-grid {
      justify-content: center;

      figure {
        width: 100%;
        min-width: auto;

        figcaption {
          justify-content: space-between;
        }
      }
    }
  }
}
