.main-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - var(--header-height));
  padding: 0;
  background: url('/src/assets/etc/background.avif') no-repeat bottom;
  background-size: cover;
  position: relative;

  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
  }

  h1, p {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: #fff;
    z-index: 1;
  }

  h1 {
    max-width: 816px;
    margin-bottom: 4.5rem;
  }

  p {
    max-width: 890px;
    font-size: 2.6rem;
    line-height: 1.6;
  }
}

@media screen and (max-width: 1080px) {
  .main-section {
    h1 {
      max-width: 460px;
      margin-bottom: 2.4rem;
    }

    p {
      max-width: 620px;
      font-size: 1.5rem;
    }
  }
}

