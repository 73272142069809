.portfolio-section {
  .portfolio-slider {
    max-width: 1000px;
    margin: 0 auto;
  }

  .portfolio-swiper {
    overflow: hidden;
    border-radius: 8px;

    img {
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 1080px) {
  .portfolio-section {
    .portfolio-slider {
      max-width: 100%;
    }
  }
}
