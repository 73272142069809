@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {
  --header-height: 60px;
  --page-max-width: 1400px;
  --container-padding: 0 2rem;
  --swiper-navigation-size: 16px;
  --swiper-theme-color: #444;
  --swiper-pagination-color: #FDEB08;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: .8;
}

html {
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 62.5%; /* 10px */
  font-weight: 400;
  font-style: normal;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
}

body {
  font-size: 1.4rem; /* 14px */
  line-height: 2.4rem; /* 24px */
  color: #444;
  padding: 0;
  margin: 0;
}

*, ::after, ::before {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p, ul, figure, fieldset {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 5rem;
  line-height: 1.2;
}

h2 {
  font-size: 4.4rem;
  line-height: 1.2;
  margin-bottom: 3.2rem;
}

h3 {
  font-size: 3.2rem;
  line-height: 1.2;
}

p, ul {
  font-size: 2.4rem;
  line-height: 1.35;
}

ul, li {
  list-style-type: none;
}

img {
  display: block;
  max-width: 100%;
}

a {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.container {
  width: 100%;
  max-width: var(--page-max-width);
  margin: 0 auto;
  padding: var(--container-padding);
  display: flex;
  flex-direction: column;
  position: relative;
}

.section {
  padding: var(--header-height) 0;
}

@media screen and (max-width: 1080px) {
  :root {
    --header-height: 40px;
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.6rem;
    margin-bottom: 1.6rem;
  }

  h3 {
    font-size: 1.8rem;
  }

  p, ul {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 480px) {
  :root {
    --container-padding: 0 1.6rem;
  }
}
