.reviews-section {
  .reviews-slider {
    .reviews-swiper {
      overflow: hidden;
    }

    .reviews-nav {
      .swiper-button-prev, .swiper-button-next {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        font-weight: bold;
        background: #FDEB08;
        border: none;
        border-radius: 50%;
        cursor: pointer;
      }

      .swiper-button-prev {
        left: -40px;
      }

      .swiper-button-next {
        right: -40px;
      }
    }
  }
}

@media (max-width: 1500px) {
  .reviews-section .reviews-slider {
    padding: 0 40px;

    .reviews-nav {
      .swiper-button-prev {
        left: 20px;
      }

      .swiper-button-next {
        right: 20px;
      }
    }
  }
}
