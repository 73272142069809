.header {
  background: #000;

  ul {
    font-size: inherit;
    line-height: inherit;
  }

  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 20;

    & + .main-section {
      margin-top: var(--header-height);
    }
  }

  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: var(--header-height);

    .logo {
      height: 100%;
      width: auto;
    }

    .main-link {
      display: flex;
      height: 100%;
    }

    .phone-link {
      font-weight: 600;
    }

    .social-list {
      display: flex;
      gap: 1.6rem;
    }

    .social-link img {
      width: 16px;
      height: 16px;
    }

    .desktop-nav {
      display: flex;
      align-items: center;

      .nav-list {
        display: flex;
        gap: 3.2rem;
      }

      .phone-link {
        padding: 0.8rem 1.2rem;
        margin-left: 5.6rem;
        margin-right: 2rem;
        font-weight: 600;
      }
    }

    .mobile-nav {
      display: none;

      .actions-list {
        display: flex;
        gap: 2.4rem;
      }

      .mobile-menu {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        background: #fff;

        opacity: 0;
        visibility: hidden;
        transition: opacity 200ms, visibility 200ms;

        &.opened {
          opacity: 1;
          visibility: visible;
          z-index: 100;
        }

        .menu-header, .menu-footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 40px;
          padding: var(--container-padding);
          background: #000;
        }

        .menu-content {
          flex: 1;
          padding: 16px;

          .nav-link {
            display: flex;
            padding: 16px 0;
            font-weight: 500;
            color: #444;
            border-bottom: 1px solid rgba(18, 18, 18, 0.08);
          }
        }
      }
    }
  }
}

@media (max-width: 1080px) {
  .header {
    .header-row {
      .desktop-nav {
        display: none;
      }

      .mobile-nav {
        display: block;
      }
    }
  }
}
